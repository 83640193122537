import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../layout/Layout';
import Seo from '../../components/Seo';
import Nav from '../../components/Nav/Nav';



const Contact = ({ data }) => {

  const data_contact = data?.allContentfulContactPage?.edges[0].node;
  
  return (
    <Layout>
      <Seo
        lang="fr"
        title={data_contact?.titleOfThePage || 'Publicis et Nous - Contact'} 
        description={data_contact?.descriptionOfThePage || 'Publicis et Nous - Contact'} 
      />
      <div id="main-wrapper" className="row">
        <Nav />
        <section id="container-contact" className="contact col-lg-9 col-md-12">
          <div className="row">
            <div id="myModal" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div id="map-canvas-modal">
                      <iframe title="google map element 1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.010772024297!2d2.371339951427725!3d48.85800497918573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66df8d84c6f39%3A0xa53e1ae70a6702f6!2s19%20Rue%20Breguet%2017%2C%2075011%20Paris%2C%20France!5e0!3m2!1sen!2smu!4v1652976709494!5m2!1sen!2smu" width="100%"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
            <div id="myModal-secondary" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div id="map-canvas-modal">
                      <iframe title="google map element 2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.0493318019244!2d2.3710987514276924!3d48.857269679185755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66df8c5f8eca9%3A0xf558ac2a9d6f8fa!2s30-34%20Rue%20du%20Chemin%20Vert%2C%2075011%20Paris%2C%20France!5e0!3m2!1sen!2smu!4v1652976572815!5m2!1sen!2smu" width="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-left-wrapper col-12 col-sm-6 col-md-6 col-lg-6">
              <div className="contact-info col-lg-12">{data_contact?.phone}<br/>
                <a href={`mailto:${data_contact?.email}`}>{data_contact?.email}</a>
                <br/>
                <span className="map-primary" data-backdrop="static" data-toggle="modal" data-target="#myModal">{data_contact?.adresseReference[0]?.adresse}</span>
                <br/>
                <span className="map-secondary" data-backdrop="static" data-toggle="modal" data-target="#myModal-secondary">{data_contact?.adresseReference[1]?.adresse}</span>
              </div>
            </div>
            {/* <div className="contact-right-wrapper col-12 col-sm-6 col-md-6 col-lg-6">
              <div className="contact-info col-lg-12">{data_contact?.textBusiness}<br/>
                <a href="mailto:business@publicisetnous.com">{data_contact?.emailBusiness}</a>
                <br/>
                <span>{data_contact?.textInternships}<br/>
                  <a href="mailto:talents@publicisetnous.com">{data_contact?.emailInternships}</a>
                </span>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulContactPage {
      edges {
        node {
          adresseReference {
            adresse
          }
          email
          descriptionOfThePage
          titleOfThePage
          phone
        }
      }
    }
  }
`

export default Contact;
